import React, { useState, useEffect } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
import "./Home.css";

export default function Home() {
  const [tasks, setTasks] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const tasks = await loadTasks();
        setTasks(tasks);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadTasks() {
    return API.get("tasks", "/tasks");
  }

  function renderTasksList(tasks) {
    return (
      <>
        <LinkContainer to="/tasks/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Add a new task</span>
          </ListGroup.Item>
        </LinkContainer>
        {tasks.map(({ taskId, content, createdAt }) => (
          <LinkContainer key={taskId} to={`/tasks/${taskId}`}>
            <ListGroup.Item action>
              <span className="font-weight-bold">
                {content.trim().split("\n")[0]}
              </span>
              <br />
              <span className="text-muted">
                Created: {new Date(createdAt).toLocaleString()}
              </span>
            </ListGroup.Item>
          </LinkContainer>
        ))}
      </>
    );
  }

  function renderLander() {
    return (
      <div className="Home">
        <div className="lander">
          <h1>Tomato Tasks</h1>
          <p className="text-muted">
            Track your Tasks with the Pomodoro Technique{" "}
          </p>
          <div className="pt-3">
            <Link to="/login" className="btn btn-info btn-lg mr-3">
              Login
            </Link>
            <Link to="/signup" className="btn btn-success btn-lg">
              Signup
            </Link>
          </div>
        </div>
      </div>
    );
  }

  function renderTasks() {
    return (
      <div className="tasks">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Tasks</h2>
        <ListGroup>{!isLoading && renderTasksList(tasks)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderTasks() : renderLander()}
    </div>
  );
}
