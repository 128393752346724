const dev = {
  STRIPE_KEY:
    "pk_test_51IEvlsH19Z41enfmYWrvRsNqzPnOaOmYxweJ7iz3J1612HtkV9RbK9k2cEIcQZKN6qdzZOo3TOZonX673egz7kga00uooZ7J7u",
  s3: {
    REGION: "eu-west-2",
    BUCKET: "dev-tasks-infra-s3-uploads4f6eb0fd-18kmdxldvobmy",
  },
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://api.tomatotasks.com/dev",
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_aGQg4WYu5",
    APP_CLIENT_ID: "2r0p5g0ushrkcuopkdhvtfkbjj",
    IDENTITY_POOL_ID: "eu-west-2:510078be-a8fd-4c9b-b7ac-eb0a44a4aa88",
  },
};

const prod = {
  STRIPE_KEY:
    "pk_test_51IEvlsH19Z41enfmYWrvRsNqzPnOaOmYxweJ7iz3J1612HtkV9RbK9k2cEIcQZKN6qdzZOo3TOZonX673egz7kga00uooZ7J7u",
  s3: {
    REGION: "eu-west-2",
    BUCKET: "prod-tasks-infra-s3-uploads4f6eb0fd-1chq38bvdkpjs",
  },
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://api.tomatotasks.com/prod",
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_01SBvUfu9",
    APP_CLIENT_ID: "76ca9umjfpbtjjmdfs4f3nidd4",
    IDENTITY_POOL_ID: "eu-west-2:f61a55f2-dc3c-4ce7-aecc-bddf52262997",
  },
};

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),
};

export default config;
